import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-85c5c3a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "use-case-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$options.isLogged)
      ? (_openBlock(), _createBlock(_component_MButton, {
          key: 0,
          label: $data.contents.buttonStartLabel,
          theme: "bordered",
          onClick: _cache[0] || (_cache[0] = 
        () =>
          _ctx.$router.push({ name: 'login', query: { redirect: _ctx.$route.fullPath } })
      )
        }, null, 8, ["label"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MButton, {
      label: $data.contents.buttonSupporLabel,
      theme: "solid-neutral",
      href: $options.supportUrl
    }, null, 8, ["label", "href"])
  ]))
}