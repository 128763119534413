<script setup lang="ts">
import isEqual from "lodash-es/isEqual";
import { computed, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import QueryParamPagination from "@/commons/components/QueryParamPagination.vue";
import NotificationForDeactivatedSecretSynchronization from "@/dashboard/components/NotificationForDeactivatedSecretSynchronization.vue";
import WarningNotificationRecommendedActions from "@/dashboard/components/WarningNotificationRecommendedActions.vue";
import ContractsList from "@/dashboard/views/ContractsList/ContractsList.vue";

import { Contract } from "@/commons/domain/models/contract";
import { ContractFilters } from "@/dashboard/domain/contract-filters";

import { EApplicationTab } from "@/commons/store/types";

defineProps({
  currentTab: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["selectMe", "displayMe", "hideMe"]);

const store = useStore();
const route = useRoute();

const application = computed(() => store.getters["currentApplication"]);

const secretSynchronizationEnabledOnApplication = computed(() => {
  return application?.value?.secretSynchronization?.isEnabled;
});

const secretSynchronizationIsEnabled = computed(() => {
  return store.getters["config/secretSynchronizationIsEnabled"];
});

const displaySecretSynchronizationDeactivatedWarningNotification = computed(
  () => {
    return (
      secretSynchronizationEnabledOnApplication.value &&
      !secretSynchronizationIsEnabled.value
    );
  },
);

const applicationId = computed(() => {
  return route.params?.id;
});

const apiKeyContracts = computed(
  () => store.getters["currentApplicationApiKeyContractsList"],
);

const apiKeyContractsCount = computed((): number => {
  return store.getters["apiKeyContractsListTotalCount"];
});

const isLoadingApplicationApiKeyContracts = computed(() =>
  store.getters["isLoadingProperty"]("apiKeyApplicationContracts"),
);

const apiKeyContractsListTotalPages = computed(
  () => store.getters["apiKeyContractsListTotalPages"],
);

const apiKeyCreatedContract = computed(
  (): Contract => store.getters["lastCreatedApiKeyContract"],
);

watch(isLoadingApplicationApiKeyContracts, () => {
  if (!isLoadingApplicationApiKeyContracts.value) {
    if (apiKeyContractsCount.value > 0) {
      emit("displayMe", apiKeyContractsCount.value);
    } else {
      emit("hideMe");
    }
    if (apiKeyCreatedContract.value != undefined) {
      emit("selectMe");
      store.dispatch("resetLastApiKeyContract");
    }
  }
});

const contractsMetrics = computed(() => {
  return application?.value?.metrics?.contracts;
});

const contractFilters = computed((): ContractFilters => {
  const query = route.query;
  return {
    deprecated: query.deprecated === "true",
    disabled: query.disabled === "true",
    aboutToExpire: query.aboutToExpire === "true",
    expired: query.expired === "true",
    apiName: (query.term as string) || "",
  };
});

const loadApplicationApiKeyContracts = async () => {
  if (contractsMetrics?.value?.apiKey > 0) {
    await store.dispatch("loadApplicationApiKeyContracts", {
      ...contractFilters.value,
      applicationId: applicationId.value,
      page: currentContractsPage.value,
    });
  }
};

const loadCurrentApplication = async () => {
  await store.dispatch("loadCurrentApplication", {
    appId: applicationId.value,
  });
};

const currentContractsPage = computed((): number => {
  const query = route.query;
  return isNaN(Number(query.page)) ? 1 : Number(query.page);
});

watch(currentContractsPage, async (newPage: number, prevPage: number) => {
  if (newPage !== prevPage) {
    await loadApplicationApiKeyContracts();
  }
});

watch(contractFilters, async (newValue, prevValue) => {
  if (isEqual(newValue, prevValue) === false) {
    await loadApplicationApiKeyContracts();
  }
});

onMounted(async () => {
  await loadApplicationApiKeyContracts();
});

const onContractChanged = async () => {
  await loadApplicationApiKeyContracts();
  await loadCurrentApplication();
};
</script>

<template>
  <div class="api-key-contracts">
    <template v-if="currentTab === EApplicationTab.API_KEY">
      <NotificationForDeactivatedSecretSynchronization
        v-if="displaySecretSynchronizationDeactivatedWarningNotification"
      />

      <WarningNotificationRecommendedActions
        :actions="application?.recommendedActions"
      />

      <ContractsList
        :contracts="apiKeyContracts"
        :isLoading="isLoadingApplicationApiKeyContracts"
        @contractChanged="onContractChanged"
      />

      <QueryParamPagination
        v-if="!isLoadingApplicationApiKeyContracts"
        class="api-key-contracts__pagination"
        :totalPagesNumber="apiKeyContractsListTotalPages"
      />
    </template>
  </div>
</template>

<style lang="scss">
.api-key-contracts {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing);
}
.api-key-contracts__pagination {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
