import { EHttpStatus } from "@/commons/request-handlers/httpResponse.type";

import { NotificationLink } from "@/commons/store/modules/notification/types";

export class HttpResponseError extends Error {
  link?: any;
}

export class CancelledHttpError extends HttpResponseError {}

export class UnreachableServerHttpError extends HttpResponseError {
  constructor(originalError: Error) {
    super("Network error: The server is unreachable", {
      cause: originalError,
    });
  }
}

// The backend doesn't handle authorization exceptions like other exceptions
// It just returns a 401 without formatting the data the same way it does with other exceptions
// That's why there is a specific UnauthorizedHttpError class
export class UnauthorizedHttpError extends HttpResponseError {
  constructor(originalError: Error) {
    super(`Unauthorized error: ${originalError.message}`, {
      cause: originalError,
    });
  }
}

export class UnknownHttpError extends HttpResponseError {
  constructor(originalError: Error) {
    super(`Unknown error: ${originalError.message}`, {
      cause: originalError,
    });
  }
}

export interface BackendHttpErrorData {
  status: EHttpStatus;
  message: string;
  timestamp: string;
  link: NotificationLink;
  subErrors: string[];
}

export class BackendHttpError extends HttpResponseError {
  backendRequestErrorData: BackendHttpErrorData;

  static buildErrorMessage(
    backendRequestErrorData: BackendHttpErrorData,
  ): string {
    let message = `${backendRequestErrorData.status}: ${backendRequestErrorData.message}`;

    if (backendRequestErrorData.subErrors?.length > 0) {
      message += "[" + backendRequestErrorData.subErrors.join(",") + "]";
    }

    return message;
  }

  static buildErrorLink(backendRequestErrorData: BackendHttpErrorData): any {
    return backendRequestErrorData.link;
  }

  constructor(backendRequestErrorData: BackendHttpErrorData) {
    super(BackendHttpError.buildErrorMessage(backendRequestErrorData));
    this.backendRequestErrorData = backendRequestErrorData;
    this.link = BackendHttpError.buildErrorLink(backendRequestErrorData);
  }

  getHttpStatus(): EHttpStatus {
    return this.backendRequestErrorData.status;
  }

  isNotFound(): boolean {
    return this.getHttpStatus() === EHttpStatus.NOT_FOUND;
  }

  isForbidden(): boolean {
    return this.getHttpStatus() === EHttpStatus.FORBIDDEN;
  }
}
