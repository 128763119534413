import { PagedResource } from "../domain/models/paged-resource";
import { ApiMapper } from "./api-mapper";
import { ApplicationMapper } from "./application-mapper";
import { OAuthTokenMapper } from "./oauth-token-mapper";
import { RateLimitsMapper } from "./rate-limits-mapper";
import { TokenMapper } from "./token-mapper";

import { Contract } from "@/commons/domain/models/contract";
import {
  ContractParameters,
  ContractParametersDTO,
} from "@/commons/domain/repositories/contract-repository";
import { ContractDto } from "@/commons/dtos/contract-dto";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";

import { EApplicationType } from "@/commons/store/types";

export class ContractMapper {
  static toDomain(
    contractsDtoPaged: PagedResourceDto<ContractDto>,
  ): PagedResource<Contract> {
    const pagedResourceContracts = {
      count: contractsDtoPaged.count,
      currentPage: contractsDtoPaged.currentPage,
      // TODO DVP-2116 remove array to dictionary transformation
      data: contractsDtoPaged.data
        .map((contract) => ContractMapper.toContractDomain(contract))
        .reduce((contractDictionary, contract) => {
          contractDictionary[contract.id] = contract;
          return contractDictionary;
        }, {}),
      totalCount: contractsDtoPaged.totalCount,
      totalPages: contractsDtoPaged.totalPages,
    };

    return pagedResourceContracts;
  }

  static toContractDomain(contractDto: ContractDto): Contract {
    return {
      id: contractDto.id,
      tokens: TokenMapper.toDomain(contractDto.tokens),
      oAuthTokens: OAuthTokenMapper.toDomain(contractDto.oauthTokens),
      rateLimits:
        contractDto.rateLimits &&
        RateLimitsMapper.toDomain(contractDto.rateLimits),
      api:
        (contractDto.api && ApiMapper.toApiDomain(contractDto.api)) ||
        undefined,
      application:
        (contractDto.application &&
          ApplicationMapper.toApplicationDomain(contractDto.application)) ||
        undefined,
      status: contractDto.status,
      allowedActions: contractDto.allowedActions,
      recommendedActions: contractDto.recommendedActions,
    };
  }

  static toGetContractDTO(params: ContractParameters): ContractParametersDTO {
    return {
      applicationId: params.applicationId,
      apiId: params.apiId,
      status: params.status?.join(","),
      applicationTypes:
        !params.applicationTypes ||
        params.applicationTypes.includes(EApplicationType.ALL)
          ? undefined
          : params.applicationTypes.join(","),
      authType: params.authType,
      oauthFlow: params.oauthFlow,
      applicationName: params.applicationName,
      disabled: params.disabled,
      aboutToExpire: params.aboutToExpire,
      deprecated: params.deprecated,
      expired: params.expired,
      page: params.page,
      size: params.size,
      apiName: params.apiName,
    };
  }
}
