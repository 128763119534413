import { AclMapper } from "./acl-mapper";

import { Application } from "@/commons/domain/models/application";
import { Dictionary } from "@/commons/domain/models/dictionary";
import { ApplicationDto } from "@/commons/dtos/application-dto";
import { CreateApplicationDto } from "@/commons/dtos/create-application-dto";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { UpdateApplicationDto } from "@/commons/dtos/update-application-dto";

export class ApplicationMapper {
  static toDomain(applicationsDtosPaged: PagedResourceDto<ApplicationDto>) {
    const applications: Dictionary<Application> = {};

    for (const ApplicationDto of applicationsDtosPaged.data) {
      const application = ApplicationMapper.toApplicationDomain(ApplicationDto);
      applications[application.id] = application;
    }

    return applications;
  }

  static toApplicationDomain(applicationDto: ApplicationDto): Application {
    const product =
      (applicationDto.product && {
        id: applicationDto.product.id,
        name: applicationDto.product.name,
      }) ||
      undefined;

    let currentUserAction = undefined;
    if (applicationDto.currentUserActions) {
      if (applicationDto.currentUserActions.includes("WRITE")) {
        currentUserAction = "READ_WRITE";
      } else if (applicationDto.currentUserActions.includes("READ")) {
        currentUserAction = "READ_ONLY";
      }
    }

    return {
      id: applicationDto.id,
      name: applicationDto.name,
      description: applicationDto.description || undefined,
      forTesting: applicationDto.forTesting,
      product,
      createdAt: applicationDto.createdAt,
      updatedAt: applicationDto.updatedAt || undefined,
      type: applicationDto.type,
      currentUserAction: currentUserAction,
      acl: AclMapper.toDomain(applicationDto.acl),
      metrics: applicationDto.metrics,
      reportingUrl: applicationDto.reportingUrl,
      ipFiltering: {
        hasSubscriptionWithIPFiltering:
          applicationDto.ipFiltering?.hasSubscriptionWithIPFiltering,
        ipRanges: applicationDto.ipFiltering?.ipRanges.sort(),
      },
      hasSubscriptionWithPublicApi: applicationDto.hasSubscriptionWithPublicApi,
      secretSynchronization: applicationDto.secretSynchronization,
      tokensRenewableForNumberOfYears:
        applicationDto.tokensRenewableForNumberOfYears,
      recommendedActions: applicationDto.recommendedActions,
      hasSubscriptionWithNotSynchronizedApi:
        applicationDto.hasSubscriptionWithNotSynchronizedApi,
    };
  }

  static toCreateApplicationDto(
    name: string,
    type: "IOS" | "ANDROID" | "WEB" | "APPLICATION_SERVER",
    description: string,
    productId: string,
    forTesting: boolean,
    groupId: string,
  ): CreateApplicationDto {
    return {
      name,
      type,
      description,
      productId: productId !== "" ? productId : null,
      forTesting,
      groupId: groupId !== "" ? groupId : null,
    };
  }

  static toUpdateApplicationDto(
    name: string,
    type: "IOS" | "ANDROID" | "WEB" | "APPLICATION_SERVER",
    description: string,
    productId: string,
    forTesting: boolean,
  ): UpdateApplicationDto {
    return {
      name,
      type,
      description,
      productId: productId !== "" ? productId : null,
      forTesting,
    };
  }
}
