import { capitalize } from "@/commons/libs/utils/stringUtils";

import { ValidationRules } from "@/commons/domain/models/validationRules.type";

import { State } from "./types";

function frontendConfig(state: State) {
  return state.frontendConfig;
}

function deployments(state: State) {
  return state.deployments;
}

function locationSettings(state: State, getters) {
  return getters.frontendConfig.locationSettings[location.host] != undefined
    ? getters.frontendConfig.locationSettings[location.host]
    : getters.frontendConfig.locationSettings["default"];
}

function supportUrl(state: State, getters) {
  return getters.locationSettings.name != undefined
    ? getters.frontendConfig.supportUrl[getters.locationSettings.name]
    : undefined;
}

function isOnlyAllowingPingLogin(state: State, getters): boolean {
  return Boolean(getters.locationSettings?.isOnlyAllowingPingLogin);
}

function styles(state: State) {
  return state.frontendConfig.styles[location.host]
    ? state.frontendConfig.styles[location.host]
    : state.frontendConfig.styles["default"];
}

function batchDashboardUrl(state: State) {
  return state.frontendConfig.batchDashboardUrl;
}

function batchDocumentationUrl(state: State) {
  return state.frontendConfig.batchDocumentationUrl;
}

function currentEnvironment(state: State): string {
  return state.environment;
}

function secretSynchronizationConfig(state: State) {
  return state.secretSynchronizationConfig;
}

function secretSynchronizationIsEnabled(state: State, getters) {
  return getters.secretSynchronizationConfig?.enabledSync;
}

function secretPublicationIsActivated(state: State, getters) {
  return (
    getters.secretSynchronizationIsEnabled &&
    getters.secretSynchronizationConfig?.enabledActivation
  );
}

function secretSyncFeatureIsInBetaStage(state: State, getters) {
  return getters.secretSynchronizationConfig?.beta;
}

function secretSynchronizationManager(state: State, getters) {
  return capitalize(getters.secretSynchronizationConfig?.secretManagerName);
}

function validationRules(state: State): ValidationRules {
  return state.validationRules;
}

function maxRequestPerUnit(state: State, getters) {
  return getters.validationRules?.maxRequestPerUnit || {};
}

function tokenExtensionLimitInMonths(state: State, getters) {
  return getters.validationRules?.tokenExtensionLimitInMonths;
}

export default {
  frontendConfig,
  deployments,
  locationSettings,
  supportUrl,
  styles,
  isOnlyAllowingPingLogin,
  batchDashboardUrl,
  batchDocumentationUrl,
  currentEnvironment,
  secretSynchronizationManager,
  secretSynchronizationConfig,
  secretSynchronizationIsEnabled,
  secretPublicationIsActivated,
  secretSyncFeatureIsInBetaStage,
  validationRules,
  maxRequestPerUnit,
  tokenExtensionLimitInMonths,
};
