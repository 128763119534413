<template>
  <Card class="settings-form">
    <h4>
      <Cluster cluster-to-right>
        <span class="settings-form__title">
          {{ apiRestTypeLabel }} {{ contents.settings }}
        </span>
        <Chip
          v-if="currentApi.isDeprecated"
          class="settings-form__deprecated"
          >{{ contents.deprecated }}</Chip
        ></Cluster
      >
    </h4>

    <form
      autocomplete="false | random-string"
      class="settings-form__wrapper"
      @submit.prevent="submit"
    >
      <div>
        <FormInput
          :tooltip="contents.productsComeFromTangram"
          :label="contents.relatedProduct"
          :error="form.firstError('product')"
        >
          <AutoCompleteSelect
            id="appRelatedProduct"
            :modelValue="currentApi.product"
            :disabled="isLoading"
            :options="productsList"
            :placeholder="contents.applicationProductLabel"
            key-property="id"
            label-property="name"
            @update:modelValue="selectProduct"
            @searchChange="searchProduct"
          />
        </FormInput>
        <!-- form.defaultError shows error even if this field was not touched, used temporarily -->
        <FormInput
          class="split-after"
          :label="contents.description"
          :error="form.defaultError('description')"
        >
          <TextArea v-model="form.description" />
        </FormInput>
      </div>

      <div class="pushed-right">
        <MButton
          theme="bordered-neutral"
          :label="contents.cancel"
          @click="cancel"
        />

        <MButton
          type="submit"
          :label="contents.applyChanges"
          :disabled="!!form.errors.length || form.pristine"
          @click="submit"
        />
      </div>
    </form>
  </Card>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import AutoCompleteSelect from "@/commons/components/AutoCompleteSelect.vue";
import Card from "@/commons/components/Card.vue";
import Chip from "@/commons/components/Chip.vue";
import Cluster from "@/commons/components/Cluster.vue";
import FormInput from "@/commons/components/FormInput.vue";
import TextArea from "@/commons/components/TextArea.vue";

import { getApiRestTypeLabel } from "@/commons/libs/utils/apiUtils";

import { Api } from "@/commons/domain/models/api";
import { searchProduct } from "@/commons/services/product/product-service";
import { ApiUpdateForm } from "@/manager/domain/forms/api-update-form";

import contents from "@/manager/contents/settings-form";

export default {
  name: "SettingsForm",
  components: {
    Card,
    Chip,
    Cluster,
    AutoCompleteSelect,
    FormInput,
    TextArea,
    MButton,
  },
  props: {
    currentApi: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: ApiUpdateForm.create(),
      persistedSettings: undefined,
      contents,
      productsList: [],
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    apiRestTypeLabel() {
      return getApiRestTypeLabel(this.currentApi.internal);
    },
  },
  async mounted() {
    const currentApi: Api = this.currentApi;

    await this.searchProduct("");

    await this.form.init({
      productId: (currentApi.product && currentApi.product.id) || null,
      description: currentApi.description,
    });

    this.persistedSettings = this.form.data();
  },
  methods: {
    async cancel() {
      await this.form.init(this.persistedSettings);
    },
    selectProduct(product) {
      this.form.productId = (product && product.id) || "";
    },
    async searchProduct(search) {
      this.productsList = await searchProduct(search);
    },
    async submit() {
      await this.form.validate();

      if (!this.form.errors.length && !this.isSaving) {
        this.persistedSettings = this.form.data();

        this.$store.dispatch("updateApiSettings", this.getUpdateApiPayload());
      }
    },
    getUpdateApiPayload() {
      const { description, productId } = this.form.data();

      return {
        description:
          this.currentApi.description !== description ? description : undefined,
        productId:
          this.currentApi.product?.id !== productId ? productId : undefined,
      };
    },
  },
};
</script>

<style>
.settings-form .settings-form__title {
  flex-grow: 1;
}

.settings-form .settings-form__deprecated {
  --chip-bg-color: var(--color-danger);
}

.settings-form .settings-form__platform {
  --chip-bg-color: var(--color-link);
}

.settings-form .form-input {
  margin-bottom: var(--base-spacing);
}

.settings-form__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
