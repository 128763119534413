import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6acf0167"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-resume" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "home-resume__buttons" }
const _hoisted_4 = { class: "home-resume__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", {
      class: "title",
      innerHTML: $options.contents.resumeHeaderTitle
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (!$options.isLogged)
        ? (_openBlock(), _createBlock(_component_MButton, {
            key: 0,
            label: $options.contents.signInButtonLabel,
            onClick: _cache[0] || (_cache[0] = 
          () =>
            _ctx.$router.push({
              name: 'login',
              query: { redirect: _ctx.$route.fullPath },
            })
        )
          }, null, 8, ["label"]))
        : (_openBlock(), _createBlock(_component_MButton, {
            key: 1,
            label: $options.contents.navigateToDashboardButtonLabel,
            onClick: _cache[1] || (_cache[1] = () => _ctx.$router.push({ name: 'dashboard' }))
          }, null, 8, ["label"]))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}