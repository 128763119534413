<template>
  <pre id="editor" />
</template>

<script lang="ts">
import AceEditor from "brace";

import("brace/theme/monokai");
import("brace/mode/javascript");

export default {
  name: "Editor",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  emits: ["input", "blur", "focus"],
  data() {
    return {
      editor: null,
    };
  },
  watch: {
    value(val) {
      if (val === this.editor.getValue()) {
        return;
      }

      this.setValue();
    },
  },
  async mounted() {
    // Configure editor
    this.editor = AceEditor.edit(this.$el);
    this.editor.session.setMode("ace/mode/javascript");
    this.editor.$blockScrolling = Infinity;
    this.editor.setOptions({ cursorStyle: "ace", fontSize: "1rem" });
    this.editor.setTheme("ace/theme/monokai");
    this.editor.setValue(this.value || "", true);

    // Register events
    this.registerEvents();
  },
  methods: {
    // This method registers all editor`s events1
    registerEvents() {
      // Blur event
      this.editor.on("blur", () => {
        this.$emit("blur", this.editor);
      });

      // Change event
      this.editor.on("change", () => {
        this.$emit("input", this.editor.getValue());
      });

      // Focus event
      this.editor.on("focus", () => {
        this.$emit("focus", this.editor);
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/commons/assets/styles/vars";

#editor {
  position: relative;
  inset: 0;
  width: 100%;
  height: 22rem;
  margin: 1rem 0 0;
  font-family: monospace;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 6px;
}
/* stylelint-disable scss/no-global-function-names */
@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  #editor {
    height: 18rem !important;
  }
}
</style>
