import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-540b0169"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "use-case-list-features" }
const _hoisted_2 = { class: "use-case-list-features__header" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "use-case-list-features__number" }
const _hoisted_5 = { class: "use-case-list-features__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($data.contents.listFeaturesHeader), 1),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.features, (feature, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `use-case-list-features-feature-${feature.name}`,
          class: "use-case-list-features__feature"
        }, [
          _createElementVNode("a", {
            class: "use-case-list-features__feature-content",
            onClick: _withModifiers(($event: any) => (
            _ctx.$emit('navigateToAnchor', `#use-case-feature-${feature.name}`)
          ), ["prevent"])
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString($options.pad(index + 1)), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(feature.title), 1)
          ], 8, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}