<template>
  <LayerModalForm class="quota-rate-limit-edition-modal" @submit="submit">
    <template #fields>
      <DvpField
        :label="contents.nbrOfRequest"
        :errorMessage="form.firstError('maxRequests')"
        :helpText="contents.nbrOfRequestHelpText(maxRateLimit)"
        :isInvalid="isInvalidMaxRequests"
      >
        <MTextInput
          data-cy="maxRequests"
          :isInvalid="isInvalidMaxRequests"
          :modelValue="form.maxRequests"
          @update:modelValue="form.maxRequests = parseInt($event, 10) || 0"
        />
      </DvpField>

      <div class="quota-rate-limit-edition-modal__frequency-section">
        <DvpField
          class="quota-rate-limit-edition-modal__frequency"
          :label="contents.frequency"
          :errorMessage="form.firstError('windowLength')"
          :isInvalid="isInvalidWindowLength"
        >
          <MQuantitySelector
            data-cy="windowLength"
            :modelValue="form.windowLength"
            :valuemin="1"
            @update:modelValue="changeWindowLength"
            @increment="changeWindowLength"
            @decrement="changeWindowLength"
          />
        </DvpField>

        <DvpField
          class="quota-rate-limit-edition-modal__unit"
          :label="contents.unit"
          :errorMessage="form.firstError('windowLengthUnit')"
        >
          <MSelect
            id="select-unit"
            v-model="form.windowLengthUnit"
            data-cy="windowLengthUnit"
            :placeholder="contents.unit"
            :options="windowLengthUnitOptions"
          />
        </DvpField>
      </div>
    </template>
    <template #formFooter>
      <MButton
        type="button"
        theme="bordered-neutral"
        :label="contents.cancel"
        @click="$emit('onClose')"
      />
      <MButton
        type="submit"
        :label="contents.submit"
        :disabled="form.errors.length > 0 || isSaving"
      />
    </template>
  </LayerModalForm>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MQuantitySelector from "@mozaic-ds/vue-3/src/components/quantityselector/MQuantitySelector.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { PropType } from "vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalForm from "@/commons/components/form/LayerModalForm.vue";

import { isString } from "@/commons/utils/string-utils";

import {
  EWindowLengthUnit,
  RateLimit,
} from "@/commons/domain/models/rate-limit";
import { RateLimitForm } from "@/manager/domain/forms/rate-limit-form";

import contents from "@/manager/contents/quota-rate-limit-edition-modal";

export default {
  components: {
    DvpField,
    MTextInput,
    MButton,
    MQuantitySelector,
    MSelect,
    LayerModalForm,
  },
  props: {
    rateLimit: {
      type: Object as PropType<RateLimit>,
      required: true,
    },
    allowedWindowLengthUnits: {
      type: Array as PropType<EWindowLengthUnit[]>,
      required: true,
    },
  },
  emits: ["onSubmit", "onClose"],
  data() {
    return {
      form: RateLimitForm.create(this.allowedWindowLengthUnits),
      contents,
    };
  },
  computed: {
    windowLengthUnitOptions() {
      return this.allowedWindowLengthUnits.map((unit) => ({
        value: unit,
        text: unit.toLowerCase(),
      }));
    },
    isSaving(): boolean {
      return this.$store.getters["isSaving"];
    },
    isInvalidMaxRequests(): boolean {
      return this.form.firstError("maxRequests") != null;
    },
    isInvalidWindowLength(): boolean {
      return this.form.firstError("windowLength") != null;
    },
    maxRateLimit(): number {
      return this.$store.getters["config/maxRequestPerUnit"][
        this.form.windowLengthUnit
      ];
    },
  },
  async created() {
    await this.form.init(this.rateLimit);
  },
  methods: {
    changeWindowLength(newValue: string | number): void {
      this.form.windowLength = isString(newValue) // value will be a string if user directly types in the input
        ? parseInt(newValue, 10)
        : newValue;
    },
    submit(): void {
      this.form.validate();

      if (!this.isSaving && this.form.errors.length === 0) {
        const { id, maxRequests, windowLength, windowLengthUnit } =
          this.form.data();

        this.$emit("onSubmit", {
          id,
          maxRequests,
          windowLength,
          windowLengthUnit,
          isEnabled: true,
          isGlobal: true,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.quota-rate-limit-edition-modal__frequency-section {
  display: flex;
  justify-content: space-between;
}

.quota-rate-limit-edition-modal__frequency,
.quota-rate-limit-edition-modal__unit {
  width: 45%;
}
</style>
