<template>
  <div class="use-case-actions">
    <MButton
      v-if="!isLogged"
      :label="contents.buttonStartLabel"
      theme="bordered"
      @click="
        () =>
          $router.push({ name: 'login', query: { redirect: $route.fullPath } })
      "
    />

    <MButton
      :label="contents.buttonSupporLabel"
      theme="solid-neutral"
      :href="supportUrl"
    />
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

const contents = {
  buttonStartLabel: "Get started",
  buttonSupporLabel: "Need help ?",
};

export default {
  name: "UseCaseActions",
  components: {
    MButton,
  },
  props: {
    isFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    isLogged() {
      return this.$store.getters["userIsLogged"];
    },
    supportUrl() {
      return this.$store.getters["config/supportUrl"];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";

.use-case-actions {
  position: relative;
  display: flex;
}

.use-case-actions a,
.use-case-actions button {
  width: auto;
  padding: 0 2.14rem;
  margin: 2rem 2rem 0 0;
}
/* stylelint-disable scss/no-global-function-names */
@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  .use-case-actions {
    flex-direction: column;
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile)) {
  .use-case-actions .button {
    width: 100%;
  }

  .use-case-actions .button.use-case-actions__button--sign-in {
    display: none;
  }
}
</style>
