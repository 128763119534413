export default {
  expiresAtLabel: (date) => `Expires at ${date}`,
  expiredSinceLabel: (date) => `Expired since ${date}`,
  disabledLabel: "Disabled",
  currentApiKeyLabel: "Current API Key",
  oldApiKeyLabel: "Old API Key",
  newApiKeyLabel: "New API Key",
  synchronizedTokenLabel: (secretManager) => `Synced with ${secretManager}`,
  pendingSyncTokenLabel: (secretManager) => `Pending ${secretManager} sync`,
};
