<template>
  <div
    :class="[
      'create-target-url',
      { 'create-target-url--disabled': isDisabled },
    ]"
  >
    <MNotification
      v-if="!isLoading && !isEditing && !availableZones.length"
      type="information"
    >
      {{ contents.noGatewaysAvailable }}
    </MNotification>

    <template v-else>
      <!-- Creation form -->
      <CardContainer v-if="showCreationForm">
        <form autocomplete="false | random-string" @submit.prevent>
          <div class="create-target-url__header">
            <!-- Header -->
            <h4>{{ contents.header }}</h4>
            <!-- Cancel and Save actions -->
            <div v-if="!isDisabled" class="create-target-url__actions">
              <MButton
                theme="bordered-neutral"
                :label="contents.buttonCancelLabel"
                @click="cancel"
              />
              <MButton
                data-cy="save-the-route"
                :label="contents.buttonSaveLabel"
                @click="create"
              />
            </div>
          </div>
          <!-- Target URL input -->
          <div
            data-cy="create-url-endpoint"
            :class="[
              {
                'has-error': urlValidationError != undefined,
              },
            ]"
          >
            <label :for="`create-target-url-input`"
              >{{ contents.targetUrl }}
              <Tooltip
                v-if="!isDisabled"
                :tooltipContent="contents.targetUrlTooltip"
              >
                <MIcon name="NotificationQuestion24"></MIcon>
              </Tooltip>
            </label>
            <TextInput
              v-model="form.url"
              class="margin-bottom-400"
              reference="inputTargetUrl"
              :placeholder="contents.targetUrlPlaceholder"
              :isDisabled="isDisabled"
              :error="urlValidationError"
              @update:modelValue="onUrlChange"
            />
          </div>
          <!-- Add or remove zones -->
          <div
            :class="[
              'gap-800',
              {
                'has-error': form.hasError('zoneIds'),
              },
            ]"
          >
            <label
              >{{ contents.gateways }}
              <Tooltip
                v-if="!isDisabled"
                :tooltipContent="contents.gatewaysTooltip"
              >
                <MIcon name="NotificationQuestion24" />
              </Tooltip>
            </label>

            <SelectZones :form="form" :is-disabled="isDisabled" />
          </div>

          <DynamicRouting
            class="gap-800"
            :routingPolicies="routingPolicies"
            :isDisabled="!isEditing"
            @update="updateRoutingPolicies"
          />
        </form>
      </CardContainer>

      <!-- Activate creation form button -->
      <div v-else>
        <button
          class="create-target-url__show-form-button btn btn-transparent"
          @click="edit"
        >
          <MIcon
            class="create-target-url__show-form-button--icon"
            name="ControlCircleMore32"
            color="var(--color-primary)"
          >
          </MIcon>
          {{ contents.buttonAdd }}
        </button>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";

import CardContainer from "@/commons/components/CardContainer.vue";
import TextInput from "@/commons/components/TextInput.vue";
import Tooltip from "@/commons/components/Tooltip/Tooltip.vue";

import DynamicRouting from "./DynamicRouting.vue";
import SelectZones from "./SelectZones.vue";

import { debounce } from "@/commons/libs/utils/debouncer";

import { validateRouteTargetUrl } from "@/commons/services/api/api-service";

import contents from "@/manager/contents/create-target-url";

export default {
  name: "CreateTargetUrl",
  components: {
    CardContainer,
    MNotification,
    SelectZones,
    DynamicRouting,
    MButton,
    MIcon,
    Tooltip,
    TextInput,
  },
  props: {
    formParent: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contents,
      isEditing: false,
      routingPolicies: [],
      urlValidationError: undefined,
    };
  },
  computed: {
    showCreationForm() {
      return this.isEditing || this.hasError;
    },
    availableZones() {
      return Object.values(this.$store.getters["availableZones"]);
    },
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    currentApi() {
      return this.$store.getters["currentApi"];
    },
  },
  created() {
    this.debouncedValidateTargetUrl = debounce(this.validateTargetUrl);
  },
  async mounted() {
    await this.form.init();
  },
  methods: {
    async create() {
      if (!this.isDisabled) {
        await this.form.validate();
        await this.formParent.validate();
        await this.validateTargetUrl();

        if (
          !this.form.errors.length &&
          !this.formParent.errors.length &&
          this.urlValidationError == undefined
        ) {
          const route = this.form.data();

          this.$store.dispatch("createRoute", {
            apiId: this.currentApi.id,
            route,
            routingPolicies: this.routingPolicies,
          });

          this.isEditing = false;
        }
      }
    },
    async cancel() {
      if (!this.isDisabled) {
        this.routingPolicies = [];
        await this.formParent.validate();
        this.isEditing = false;
      }
    },
    async edit() {
      if (!this.isDisabled) {
        this.form.clear();
        this.urlValidationError = undefined;
        this.isEditing = true;
      }
    },
    updateRoutingPolicies(updatedRoutingPolicies) {
      this.routingPolicies = updatedRoutingPolicies;
    },
    async onUrlChange(newUrl) {
      await this.form.update({ url: newUrl });
      this.debouncedValidateTargetUrl();
    },
    async validateTargetUrl() {
      const validation = await validateRouteTargetUrl(this.form.url);
      this.urlValidationError = validation.message;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/lib/button-mixin";

.create-target-url {
  label {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    text-transform: uppercase;
  }
}

.create-target-url--disabled {
  background-color: var(--color-background-interface);
}

.create-target-url__show-form-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 1.2rem;
  font-size: 1rem;
  font-weight: 700;
  color: var(--color-text);
  text-transform: initial;
  border: dashed 1px var(--color-light-grey);
  border-radius: 0;
}

.create-target-url__show-form-button--icon {
  margin-right: 0.5rem;
}

.create-target-url__header,
.create-target-url__actions {
  display: flex;
  align-items: center;
}

.create-target-url__actions {
  margin-left: auto;

  .btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
    /* stylelint-disable scss/no-global-function-names */
    &.btn-secondary {
      @include button(#767676, #fafafa, lighten(#fafafa, 3%));
    }
    /* stylelint-enable scss/no-global-function-names */
  }
}

.create-target-url__actions > * {
  margin-left: 1rem;
}
</style>
