<template>
  <div class="contract-card-actions-menu">
    <div class="contract-card-actions-menu__header">
      <h6 class="contract-card-actions-menu__header-title">
        {{ title }}
      </h6>
      <IconButton
        class="contract-card-actions-menu__close-button"
        data-cy="actions-close-button"
        mozaicIconName="ControlCircleCross24"
        @click="emit('clickOnClose')"
      />
    </div>
    <div class="contract-card-actions-menu__items">
      <div
        v-for="item of actionItems"
        :key="item.actionName"
        class="contract-card-actions-menu__item-container"
      >
        <Divider
          v-if="item.isSensitiveAction && actionItems?.length > 1"
          class="contract-card-actions-menu__divider"
        />
        <div
          class="contract-card-actions-menu__item"
          :class="item.extraClass"
          @click="emit(item.actionName, token)"
        >
          <MIcon :name="item.iconName" />
          <template v-if="item.actionName === 'extendToken'">
            <span :data-cy="`token-action-${item.actionName}`">{{
              getExtendTokenLabel(item)
            }}</span>
          </template>
          <template v-else-if="item.actionName === 'renewSubscription'">
            <span :data-cy="`token-action-${item.actionName}`">{{
              getRenewSubscriptionLabel(item)
            }}</span>
          </template>
          <template v-else>
            <span :data-cy="`token-action-${item.actionName}`">{{
              item.label
            }}</span>
          </template>
          <MBadge v-if="actionIsRecommended(item)" type="success"
            >{{ contents.recommended }}
          </MBadge>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import { PropType, computed } from "vue";
import { useStore } from "vuex";

import Divider from "@/commons/components/Divider.vue";
import IconButton from "@/commons/components/IconButton.vue";

import { pluralize } from "@/commons/utils/contents-utils";
import { ContractCardActionItem } from "@/dashboard/utils/token-action-utils";

import { Application } from "@/commons/domain/models/application";
import { Contract } from "@/commons/domain/models/contract";
import { Token } from "@/commons/domain/models/token";

import contents from "@/dashboard/contents/token-action";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  actionItems: {
    type: Array as PropType<ContractCardActionItem[]>,
    required: true,
  },
  contract: {
    type: Object as PropType<Contract>,
    required: true,
  },
  token: {
    type: Object as PropType<Token>,
    default: undefined,
  },
});

const emit = defineEmits([
  "extendToken",
  "deleteToken",
  "unsubscribe",
  "renewSubscription",
  "manageApiSettings",
  "clickOnClose",
]);

const store = useStore();

const extensionLimitInMonths = computed((): number => {
  return store.getters["config/tokenExtensionLimitInMonths"];
});

const currentApplication = computed((): Application => {
  return store.getters["currentApplication"];
});

const tokenRenewableForNumberOfYears = computed((): number => {
  return currentApplication.value.tokensRenewableForNumberOfYears;
});

const remainingExtensions = computed((): number => {
  return props.token.remainingExtensions;
});

const getExtendTokenLabel = (item: ContractCardActionItem): string => {
  return `${item.label} (${remainingExtensions.value} remaining) for ${pluralize(extensionLimitInMonths.value, "month")}`;
};

const getRenewSubscriptionLabel = (item: ContractCardActionItem): string => {
  return `${item.label} for ${pluralize(tokenRenewableForNumberOfYears.value, "year")}`;
};

const actionIsRecommended = (actionItem: ContractCardActionItem): boolean => {
  return (
    actionItem.isRecommendedPredicate != undefined &&
    actionItem.isRecommendedPredicate(props.contract)
  );
};
</script>

<style lang="scss">
.contract-card-actions-menu {
  display: flex;
  flex-direction: column;

  padding: 1.5rem;

  background-color: var(--color-background-primary);
  border-radius: 0.5rem;
}

.contract-card-actions-menu__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 2.5rem;
}

.contract-card-actions-menu__header-title {
  margin: 0;
}

.contract-card-action-item--critical {
  color: var(--color-status-danger);
}

.contract-card-actions-menu__items {
  display: flex;
  flex-direction: column;
}

.contract-card-actions-menu__item-container {
  display: flex;
  flex-direction: column;
}

.contract-card-actions-menu__item {
  display: flex;
  align-items: center;

  padding: 0.4375rem 0.75rem;

  gap: 0.5rem;

  cursor: pointer;
}

.contract-card-actions-menu__item:hover {
  background-color: var(--color-background-secondary-light);
  border-radius: 0.25rem;
}

.contract-card-actions-menu__divider {
  margin: 0.5rem 0;
}
</style>
