<template>
  <div class="contract-card">
    <ContractCardContainer>
      <ContractInformation
        :contract="contract"
        :showActionsButton="actionsMenu.subscriptionActions?.length > 0"
        :openActionsMenu="() => actionsMenu.openSubscriptionActions()"
      />

      <ContractApiKeys
        v-if="isApiKeyContract"
        :contract="contract"
        @openActionsMenu="(token: Token) => actionsMenu.openTokenActions(token)"
      />
    </ContractCardContainer>

    <ContractCardActionsMenu
      v-if="actionsMenu.isOpen"
      ref="actionsMenuTarget"
      class="contract-card__actions-menu"
      :title="actionsMenu.title"
      :actionItems="actionsMenu.items"
      :contract="contract"
      :token="actionsMenu.selectedToken"
      :isOAuthContract="isOauthContract"
      @renewSubscription="renewSubscription"
      @extendToken="extendToken"
      @deleteToken="openDeleteTokenModal"
      @unsubscribe="openUnsubscriptionModal"
      @manageApiSettings="openApiSettingsTab"
      @clickOnClose="actionsMenu.close"
    />
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { computed, markRaw, onMounted, PropType, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import ContractCardActionsMenu from "@/dashboard/views/ContractsList/contractCard/ContractCardActionsMenu.vue";
import ContractCardContainer from "@/dashboard/views/ContractsList/contractCard/ContractCardContainer.vue";
import ContractInformation from "@/dashboard/views/ContractsList/contractCard/ContractInformation.vue";
import ContractApiKeys from "@/dashboard/views/ContractsList/contractCard/tokenDisplay/ContractApiKeys.vue";
import ContractDeleteTokenModal from "@/dashboard/views/ContractsList/modal/ContractDeleteTokenModal.vue";
import ContractRotateTokenModal from "@/dashboard/views/ContractsList/modal/ContractRotateTokenModal.vue";
import ContractUnsubscriptionModal from "@/dashboard/views/ContractsList/modal/ContractUnsubscriptionModal.vue";

import {
  ContractCardActionItem,
  getSubscriptionActionsMenu,
  getTokenActionsMenu,
} from "@/dashboard/utils/token-action-utils";

import { Application } from "@/commons/domain/models/application";
import { Contract } from "@/commons/domain/models/contract";
import { OAuthToken } from "@/commons/domain/models/oauth-token";
import { Token } from "@/commons/domain/models/token";

import contentsOfDeleteTokenModal from "@/dashboard/contents/contract-delete-token-modal";
import contentsOfRotateTokenModal from "@/dashboard/contents/contract-rotate-token-modal";
import contentsOfUnsubscriptionModal from "@/dashboard/contents/contract-unsubscription-modal";
import contentsOfActionsMenu from "@/dashboard/contents/token-action";

const props = defineProps({
  contract: {
    type: Object as PropType<Contract>,
    required: true,
  },
});

const emit = defineEmits(["contractChanged"]);

const store = useStore();
const router = useRouter();

onMounted(() => {
  actionsMenu.subscriptionActions = getSubscriptionActionsMenu(props.contract);
});

const actionsMenu = reactive({
  items: [],
  title: undefined,
  selectedToken: undefined, // OR token to renew for Subscription Actions
  subscriptionActions: [],
  isOpen: false,
  initItems: (actionsItems: ContractCardActionItem[]) => {
    actionsMenu.items = actionsItems;
  },
  openSubscriptionActions: () => {
    actionsMenu.title = contentsOfActionsMenu.subscriptionMenuTitle;
    actionsMenu.initItems(actionsMenu.subscriptionActions);
    actionsMenu.selectedToken = apikeyTokens.value[0]; // renew is possible only if contract has one token
    actionsMenu.isOpen = true;
  },
  openTokenActions: (token: Token) => {
    actionsMenu.title = contentsOfActionsMenu.apiKeyMenuTitle;
    actionsMenu.selectedToken = token;
    actionsMenu.initItems(getTokenActionsMenu(token));
    actionsMenu.isOpen = true;
  },
  close: () => {
    actionsMenu.isOpen = false;
    actionsMenu.selectedToken = undefined;
    actionsMenu.title = undefined;
    actionsMenu.items = [];
  },
});

const application = computed((): Application => {
  return store.getters["currentApplication"];
});

const secretSyncActivatedOnApplication = computed((): boolean => {
  return application.value.secretSynchronization?.isEnabled;
});

const oauthTokens = computed((): OAuthToken[] => {
  return Object.values<OAuthToken>(props.contract?.oAuthTokens);
});

const apikeyTokens = computed((): Token[] => {
  return Object.values<Token>(props.contract?.tokens);
});

const isOauthContract = computed((): boolean => {
  return oauthTokens.value.length > 0;
});

const isApiKeyContract = computed((): boolean => {
  return apikeyTokens.value.length > 0;
});

const isOAuthContractWithSharedOrMultiLinedToken = computed((): boolean => {
  if (isOauthContract.value) {
    return oauthTokens.value[0]?.shared || oauthTokens.value[0]?.multiLined;
  }
  return false;
});

const secretSynchronizationIsEnabled = computed((): boolean => {
  return store.getters["config/secretSynchronizationIsEnabled"];
});

const secretSyncWillOccurLater = computed((): boolean => {
  return (
    secretSyncActivatedOnApplication.value &&
    !secretSynchronizationIsEnabled.value
  );
});

const renewSubscription = async (token: Token) => {
  actionsMenu.close();
  store.commit("openLayerModal", {
    title: contentsOfRotateTokenModal.modalTitle,
    component: markRaw(ContractRotateTokenModal),
    props: {
      applicationId: application.value?.id,
      tokenId: token.id,
      contractId: props.contract?.id,
      withEnabledSecretSynchronization: secretSyncActivatedOnApplication.value,
      secretSyncWillOccurLater: secretSyncWillOccurLater.value,
    },
    listeners: {
      submitted: () => emitContractChanged(),
    },
  });
};

const extendToken = async (token: Token) => {
  actionsMenu.close();
  await store.dispatch("extendToken", {
    appId: application.value?.id,
    contractId: props.contract?.id,
    tokenId: token.id,
  });
  emitContractChanged();
};

const openDeleteTokenModal = (token: Token) => {
  actionsMenu.close();
  store.commit("openLayerModal", {
    title: contentsOfDeleteTokenModal.modalTitle,
    component: markRaw(ContractDeleteTokenModal),
    props: {
      token: token,
      contract: props.contract,
      withEnabledSecretSynchronization: secretSyncActivatedOnApplication.value,
    },
    listeners: {
      submitted: () => emitContractChanged(),
    },
  });
};

const openUnsubscriptionModal = () => {
  actionsMenu.close();
  store.commit("openLayerModal", {
    title: contentsOfUnsubscriptionModal.modalTitle,
    component: markRaw(ContractUnsubscriptionModal),
    props: {
      contract: props.contract,
      isOAuthContract: isOauthContract.value,
      isOAuthContractWithSharedOrMultiLinedToken:
        isOAuthContractWithSharedOrMultiLinedToken.value,
      withEnabledSecretSynchronization: secretSyncActivatedOnApplication.value,
    },
    listeners: {
      submitted: () => emitContractChanged(),
    },
  });
};

const openApiSettingsTab = () => {
  actionsMenu.close();
  const routeData = router.resolve({
    name: "managerApiDetailSettings",
    params: {
      id: props.contract?.api?.id,
    },
  });
  window.open(routeData.href, "_blank");
};

const emitContractChanged = () => {
  emit("contractChanged");
};

// Handle closing actions menu by clicking outside

const actionsMenuTarget = ref(null);

onClickOutside(actionsMenuTarget, () => {
  actionsMenu.close();
});
</script>

<style lang="scss">
.contract-card {
  position: relative;

  height: 100%;
}

.contract-card__actions-menu {
  position: absolute;
  inset: 0;
}
</style>
