<template>
  <CardContainer v-if="form">
    <form
      :class="[
        'update-or-remove-target-url',
        { 'update-or-remove-target-url--disabled': isDisabled },
        { 'update-or-remove-target-url--not-editing': !isEditing },
      ]"
      autocomplete="false | random-string"
      @submit.prevent
    >
      <!-- Header and actions-->
      <div class="update-or-remove-target-url__header">
        <!-- Header -->
        <h4>{{ contents.header }}</h4>
        <!-- Position index in list -->
        <div class="update-or-remove-target-url__position-in-list">
          {{ positionInList }}
        </div>
        <!-- Remove, Cancel, Save and Edit actions -->
        <div v-if="!isDisabled" class="update-or-remove-target-url__actions">
          <template v-if="isEditing">
            <Tooltip
              v-if="existEnabledDownstreamRoutes"
              :tooltipContent="contents.unpublishedTooltip"
            >
              <MButton
                theme="bordered-danger"
                disabled
                :label="contents.buttonRemoveLabel"
              />
            </Tooltip>
            <MButton
              v-else
              theme="bordered-danger"
              :label="contents.buttonRemoveLabel"
              @click="remove"
            />
            <MButton
              theme="bordered-neutral"
              :label="contents.buttonCancelLabel"
              @click="cancel"
            />
            <MButton
              data-cy="save-the-route"
              :label="contents.buttonUpdateLabel"
              @click="update"
            />
          </template>
          <template v-else>
            <MButton
              theme="solid-neutral"
              :label="contents.buttonEditLabel"
              @click="edit"
            />
          </template>
        </div>
      </div>
      <!-- Target URL input -->
      <div data-cy="url-endpoint">
        <label :for="`update-or-remove-target-url-${form.id}`"
          >{{ contents.targetUrl }}
          <Tooltip
            v-if="!isDisabled && isEditing"
            :tooltipContent="contents.targetUrlTooltip"
          >
            <MIcon name="NotificationQuestion24" />
          </Tooltip>
        </label>

        <TextInput
          v-model="url"
          class="margin-bottom-400"
          reference="inputTargetUrl"
          :placeholder="contents.targetUrlPlaceholder"
          :isDisabled="!isEditing"
          :error="urlValidationError"
        />
      </div>
      <!-- Add or remove zones -->
      <div
        :class="[
          'gap-800',
          {
            'has-error': form.hasError('zoneIds'),
          },
        ]"
      >
        <label
          >{{ contents.gateways }}
          <Tooltip
            v-if="!isDisabled && isEditing"
            :tooltipContent="contents.gatewaysTooltip"
          >
            <MIcon name="NotificationQuestion24" />
          </Tooltip>
        </label>

        <MNotification
          v-if="isEditing"
          class="update-or-remove-target-url__alert-message-gateway"
          type="information"
        >
          {{ contents.infoAskAnotherGateway }}
        </MNotification>

        <SelectZones :form="form" :is-disabled="!isEditing" />
      </div>
      <div
        class="update-or-remove-target-url__dynamics-routing"
        :class="{
          'update-or-remove-target-url__dynamics-routing--disabled': isDisabled,
        }"
      >
        <DynamicRouting
          :routingPolicies="currentlyUpdatingRoutingPolicies"
          :routeEntryPoints="downstreamEntryPoints"
          :isDisabled="!isEditing"
          :isDisplayDynRoutingNotification="isDisplayDynRoutingNotification"
          @update="updateRoutingPolicies"
          @displayNotification="displayDynRoutingNotification"
        />
      </div>
    </form>
  </CardContainer>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";

import CardContainer from "@/commons/components/CardContainer.vue";
import TextInput from "@/commons/components/TextInput.vue";
import Tooltip from "@/commons/components/Tooltip/Tooltip.vue";

import DynamicRouting from "./DynamicRouting.vue";
import SelectZones from "./SelectZones.vue";

import { debounce } from "@/commons/libs/utils/debouncer";

import { Dictionary } from "@/commons/domain/models/dictionary";
import { Route } from "@/commons/domain/models/route";
import { validateRouteTargetUrl } from "@/commons/services/api/api-service";

import { ERouteTypes } from "@/commons/store/types";

import contents from "@/manager/contents/update-or-remove-target-url";

export default {
  name: "UpdateOrRemoveTargetUrl",
  components: {
    CardContainer,
    MNotification,
    DynamicRouting,
    SelectZones,
    Tooltip,
    TextInput,
    MIcon,
    MButton,
  },
  props: {
    formParent: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      default: null,
    },
    positionInList: {
      type: Number,
      default: 1,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contents,
      isEditing: false,
      persistedFormData: undefined,
      currentUpstreamId: "",
      currentlyUpdatingRoutingPolicies: [],
      isDisplayDynRoutingNotification: false,
      urlValidationError: undefined,
    };
  },
  computed: {
    url: {
      get(): string {
        return this.form.url;
      },
      set(newUrl: string) {
        this.form.url = newUrl;
        this.debouncedValidateTargetUrl();
      },
    },
    routingPolicies() {
      return (
        this.currentRouteUpstream && this.currentRouteUpstream.routingPolicies
      );
    },
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    routes(): Dictionary<Route> {
      return this.$store.getters["routes"];
    },
    currentRouteUpstream() {
      return Object.values(this.routes).find(
        (route: Route) => route.id === this.currentUpstreamId,
      );
    },
    downstreamEntryPoints() {
      const upstreamRoute = this.currentRouteUpstream;
      if (!upstreamRoute) {
        return [];
      }
      return Object.values(this.routes).filter(
        (route: Route) =>
          route.type === ERouteTypes.DOWNSTREAM &&
          upstreamRoute.zoneIds.includes(route.zoneIds[0]),
      );
    },
    existEnabledDownstreamRoutes(): boolean {
      return !this.currentRouteUpstream.isRemovable;
    },
  },
  watch: {
    routingPolicies: {
      handler(newRoutingPolicies) {
        this.currentlyUpdatingRoutingPolicies = newRoutingPolicies;
      },
      deep: true,
    },
  },
  created() {
    this.debouncedValidateTargetUrl = debounce(this.validateTargetUrl);
  },
  mounted() {
    this.currentUpstreamId = this.form.id;
    this.currentlyUpdatingRoutingPolicies = this.routingPolicies;
  },
  methods: {
    displayDynRoutingNotification() {
      this.isDisplayDynRoutingNotification = true;
    },
    async update() {
      this.isDisplayDynRoutingNotification = false;
      if (!this.isDisabled) {
        await this.form.validate();
        await this.formParent.validate();
        await this.validateTargetUrl();

        if (
          !this.form.errors.length &&
          !this.formParent.errors.length &&
          this.urlValidationError == undefined
        ) {
          const route = this.form.data();
          await this.$store.dispatch("updateRoute", {
            apiId: this.currentApi.id,
            routeId: route.id,
            ...route,
            routingPolicies: Object.values(
              this.currentlyUpdatingRoutingPolicies,
            ),
          });
          await this.$store.dispatch("availableZones", this.currentApi.id);
          this.persistedFormData = this.form.data();
          this.isEditing = false;
        }
      }
    },
    async cancel() {
      if (!this.isDisabled) {
        await this.form.init(this.persistedFormData);
        await this.formParent.validate();
        this.urlValidationError = undefined;
        this.currentlyUpdatingRoutingPolicies = this.routingPolicies;
        this.isEditing = false;
      }
    },
    async remove() {
      const result = await this.$swal({
        titleText: this.contents.headerConfirmRemove,
        text: this.contents.unPublishRouteModalText,
        confirmButtonText: this.contents.unPublishRouteConfirmButtonText,
        input: "text",
        inputPlaceholder: this.contents.unPublishRouteInputPlaceholder,
      });
      if (result.isConfirmed && result.value === "DELETE") {
        this.$store.dispatch("deleteRoute", {
          apiId: this.currentApi.id,
          routeId: this.currentRouteUpstream && this.currentRouteUpstream.id,
        });
      }
    },
    edit() {
      if (!this.isDisabled) {
        this.persistedFormData = this.form.data();
        this.urlValidationError = undefined;
        this.isEditing = true;
      }
    },
    updateRoutingPolicies(updatedRoutingPolicies) {
      this.currentlyUpdatingRoutingPolicies = updatedRoutingPolicies;
    },
    async validateTargetUrl() {
      const validation = await validateRouteTargetUrl(this.form.url);
      this.urlValidationError = validation.message;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/lib/button-mixin";

.update-or-remove-target-url {
  label {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  label .tooltip {
    text-transform: none;
  }
}

.update-or-remove-target-url--disabled,
.update-or-remove-target-url--not-editing {
  background-color: var(--color-background-interface);

  h4,
  label {
    color: var(--color-grey);
  }

  .update-or-remove-target-url__position-in-list {
    background-color: var(--color-grey);
  }

  .input-group.input-group-light input {
    background-color: var(--color-background-interface);
  }
}

.update-or-remove-target-url--not-editing {
  background-color: var(--color-white);
}

.update-or-remove-target-url__wrapper {
  padding: 2rem;
  background-color: var(--color-white);
  border: 1px solid;
  border-radius: 6px;
}

.update-or-remove-target-url__header,
.update-or-remove-target-url__actions {
  display: flex;
  align-items: center;
}
.update-or-remove-target-url__actions-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
  .btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
    /* stylelint-disable scss/no-global-function-names */
    &.btn-secondary {
      @include button(#767676, #fafafa, lighten(#fafafa, 3%));
    }
    /* stylelint-enable scss/no-global-function-names */

    &.btn-outline-danger {
      color: var(--color-danger);
    }
  }
}
.update-or-remove-target-url__position-in-list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  margin: 0 1rem;
  font-size: 1.1rem;
  color: var(--color-white);
  background-color: black;
  border-radius: 20px;
}

.update-or-remove-target-url__actions {
  margin-left: auto;

  .btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
    /* stylelint-disable scss/no-global-function-names */
    &.btn-secondary {
      @include button(#767676, #fafafa, lighten(#fafafa, 3%));
    }
    /* stylelint-enable scss/no-global-function-names */

    &.btn-outline-danger {
      color: var(--color-danger);
    }
  }
}

.update-or-remove-target-url__dynamics-routing {
  margin-top: 2.5rem;
}

.update-or-remove-target-url__dynamics-routing--disabled {
  border-top: var(--border-default);
}

.update-or-remove-target-url__alert-message-gateway {
  margin-bottom: 16px;
}

.update-or-remove-target-url__actions > * {
  margin-left: 1rem;
}
</style>
