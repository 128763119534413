<template>
  <div class="acl last-pushed">
    <div class="acl__item">
      <IconGroup :provider="provider" />
      <a href="#" @click="showUserModal">{{ groupName }}</a>
    </div>
    <ButtonDelete @click="emit('remove', groupId)" />
  </div>
</template>

<script setup lang="ts">
import { markRaw } from "vue";
import { useStore } from "vuex";

import ButtonDelete from "@/commons/components/Buttons/ButtonDelete.vue";
import IconGroup from "@/commons/components/Icons/IconGroup.vue";
import GroupUsersModal from "@/commons/components/ManageGroups/GroupUsersModal.vue";

import contentsGroupUsersModal from "@/commons/contents/group-users-modal";

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
  groupName: {
    type: String,
    required: true,
  },
  provider: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["remove"]);

const store = useStore();

const showUserModal = async () => {
  store.commit("openModal", {
    title: contentsGroupUsersModal.modalTitle,
    component: markRaw(GroupUsersModal),
    props: {
      groupName: props.groupName,
      groupId: props.groupId,
    },
  });
};
</script>

<style lang="scss">
.acl button {
  background: none;
}

.acl__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
</style>
