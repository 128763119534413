<template>
  <div class="flat-menu">
    <div class="flat-menu__label">
      <a v-if="!!url" :href="url" class="flat-menu__link-label">{{ label }}</a>
      <template v-else>{{ label }}</template>
    </div>
    <div class="flat-menu__menu">
      <MenuItem
        v-for="(item, index) in items"
        v-bind="item"
        :key="`${id}-${index}`"
        :isDisplayImage="isDisplayImage"
        :isDisplayDescription="isDisplayDescription"
        colorWhite
      />
    </div>
  </div>
</template>

<script lang="ts">
import MenuItem from "./MenuItem.vue";

import { validateComponentProps } from "@/commons/libs/validate-component-props";

export default {
  name: "FlatMenu",
  components: {
    MenuItem,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
      validator(values) {
        return values.every((menuItemProps) =>
          validateComponentProps(MenuItem, menuItemProps),
        );
      },
    },
    isDisplayImage: {
      type: Boolean,
      default: true,
    },
    isDisplayDescription: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    id() {
      return `flat-menu-id-${this.$.uid}`;
    },
  },
};
</script>

<style lang="scss">
@import "~@/commons/assets/styles/vars";

.flat-menu__label {
  margin: 0 0 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.flat-menu__link-label {
  color: var(--color-text);
  text-decoration: none;
}

.flat-menu__menu {
  display: inline-block;
  padding-bottom: 0;
}
/* stylelint-disable scss/no-global-function-names */
@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  .flat-menu__menu {
    padding: 0;
  }
}
@media only screen and (max-width: map-get($breakpoints, mobile)) {
  .flat-menu__menu {
    padding: 0 0 2rem;
  }

  .flat-menu__menu .menu-item {
    padding: 0 0 1rem !important;
  }

  .flat-menu__label {
    text-align: center;
  }
}
</style>
