import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-35b82955"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "use-case-feature__description" }
const _hoisted_2 = { class: "use-case-feature__title" }
const _hoisted_3 = { class: "use-case-feature__kicker" }
const _hoisted_4 = { class: "use-case-feature__key-features" }
const _hoisted_5 = { class: "use-case-feature__actions" }
const _hoisted_6 = { class: "use-case-feature__image" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_use_case_actions = _resolveComponent("use-case-actions")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['use-case-feature', { 'use-case-feature--inversed': $options.isOdd }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString($props.title), 1),
      _createElementVNode("h2", _hoisted_3, _toDisplayString($props.kicker), 1),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.keyFeatures, (keyFeature, keyFeatureIndex) => {
          return (_openBlock(), _createElementBlock("li", {
            key: `use-case-feature-key-${keyFeatureIndex}`,
            class: "use-case-feature__key-feature"
          }, _toDisplayString(keyFeature), 1))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_use_case_actions)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("img", { src: $props.image }, null, 8, _hoisted_7)
    ])
  ], 2))
}