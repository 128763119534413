<template>
  <div :class="['use-case-feature', { 'use-case-feature--inversed': isOdd }]">
    <div class="use-case-feature__description">
      <h1 class="use-case-feature__title">{{ title }}</h1>
      <h2 class="use-case-feature__kicker">{{ kicker }}</h2>
      <ul class="use-case-feature__key-features">
        <li
          v-for="(keyFeature, keyFeatureIndex) in keyFeatures"
          :key="`use-case-feature-key-${keyFeatureIndex}`"
          class="use-case-feature__key-feature"
        >
          {{ keyFeature }}
        </li>
      </ul>
      <div class="use-case-feature__actions">
        <use-case-actions />
      </div>
    </div>
    <div class="use-case-feature__image">
      <img :src="image" />
    </div>
  </div>
</template>

<script lang="ts">
import UseCaseActions from "./UseCaseActions.vue";

export default {
  name: "UseCaseFeature",
  components: {
    UseCaseActions,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    kicker: {
      type: String,
      default: "",
    },
    keyFeatures: {
      type: Array,
      default: () => [],
    },
    image: {
      type: String,
      default: null,
    },
    carousel: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isOdd() {
      return this.index % 2 !== 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";
@import "~@/showcase/views/common/home";

.use-case-feature {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  width: 91.4rem;
  margin: 0;
}

.use-case-feature__description {
  display: flex;

  flex: 0.5;
  flex-direction: column;

  margin: 0 4rem 0 0;
}

.use-case-feature__image {
  display: flex;

  flex: 0.5;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.use-case-feature__detail {
  &:nth-child(odd) {
    .use-case-feature__description {
      order: 2;
      margin: 0;
    }

    .use-case-feature__image {
      margin: 0 4rem 0 0;
    }
  }
}

.use-case-feature__image img {
  max-width: 95%;
  max-height: 95%;
}

.use-case-feature__title {
  margin-bottom: 2rem;

  font-size: 3.71rem;
  line-height: 4.57rem;
}

.use-case-feature__kicker {
  margin-bottom: 2rem;

  font-size: 1.71rem;
  font-weight: 200;
  line-height: 2.57rem;
}

.use-case-feature__key-features {
  font-size: 1.29rem;
  font-weight: 200;
  line-height: 1.79rem;

  list-style: none;
}

.use-case-feature__key-feature {
  padding-bottom: 2rem;
  padding-left: 3rem;
  font-size: 1.1rem;
  line-height: 2rem;

  background: url("~@/commons/assets/images/list-checked.svg") no-repeat 0 0;
  background-size: 2rem;
}
/* stylelint-disable scss/no-global-function-names */
@media only screen and (max-width: map-get($breakpoints, desktop-small)) {
  .use-case-feature {
    width: 90%;
    margin: 0;
  }

  .use-case-feature__description {
    flex: 0.55;

    margin: 0 3rem 0 0;
  }

  .use-case-feature__image {
    flex: 0.45;
  }

  .use-case-feature__title {
    margin-bottom: 1rem;

    font-size: 3rem;
    line-height: 4rem;
  }

  .use-case-feature__kicker {
    margin-bottom: 2rem;

    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  .use-case-feature {
    width: 90%;
    margin: 0;
  }

  .use-case-feature__description {
    flex: 0.55;

    margin: 0 3rem 0 0;
  }

  .use-case-feature__image {
    flex: 0.45;
  }

  .use-case-feature__title {
    margin-bottom: 1rem;

    font-size: 3rem;
    line-height: 4rem;
  }

  .use-case-feature__kicker {
    margin-bottom: 2rem;

    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .use-case-feature__key-feature {
    padding-bottom: 1.2rem;
    padding-left: 2.4rem;

    background: url("~@/commons/assets/images/list-checked.svg") no-repeat 0 2px;
    background-size: 1.6rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, tablet-portrait)) {
  .use-case-feature__description {
    order: 2;
    margin: 0;
  }

  .use-case-feature__image {
    margin: 0 0 0.5rem;
  }

  .use-case-feature__detail {
    &:nth-child(odd) {
      .use-case-feature__description {
        order: 2;
        margin: 0;
      }

      .use-case-feature__image {
        margin: 0 0 1.5rem;
      }
    }
  }

  .use-case-feature {
    flex-direction: column;

    width: 90%;
  }

  .use-case-feature__image img {
    max-width: 100%;
    max-height: 38vh;
  }

  .use-case-feature__title {
    margin: 0 0 1rem;
    font-size: 3rem;
    line-height: 4rem;
  }

  .use-case-feature__kicker {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile)) {
  .use-case-feature__description {
    flex: 1 100%;

    order: 2;
    margin: 0 0 4rem;
  }

  .use-case-feature__image {
    flex: 1 100%;
    align-items: center;
    justify-content: center;
    margin: 0 0 1.5rem;
  }

  .use-case-feature__detail {
    &:nth-child(odd) {
      .use-case-feature__description {
        order: 2;
        margin: 0;
      }

      .use-case-feature__image {
        margin: 0;
      }
    }
  }

  .use-case-feature {
    flex-direction: column;

    width: 90%;
  }

  .use-case-feature__image img {
    max-height: 60vh;
  }

  .use-case-feature__title {
    margin: 0 0 1rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .use-case-feature__kicker {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}
</style>
