import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6380240c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "update-or-remove-target-url__header" }
const _hoisted_2 = { class: "update-or-remove-target-url__position-in-list" }
const _hoisted_3 = {
  key: 0,
  class: "update-or-remove-target-url__actions"
}
const _hoisted_4 = { "data-cy": "url-endpoint" }
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_SelectZones = _resolveComponent("SelectZones")!
  const _component_DynamicRouting = _resolveComponent("DynamicRouting")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return ($props.form)
    ? (_openBlock(), _createBlock(_component_CardContainer, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            class: _normalizeClass([
        'update-or-remove-target-url',
        { 'update-or-remove-target-url--disabled': $props.isDisabled },
        { 'update-or-remove-target-url--not-editing': !$data.isEditing },
      ]),
            autocomplete: "false | random-string",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("h4", null, _toDisplayString($data.contents.header), 1),
              _createElementVNode("div", _hoisted_2, _toDisplayString($props.positionInList), 1),
              (!$props.isDisabled)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    ($data.isEditing)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          ($options.existEnabledDownstreamRoutes)
                            ? (_openBlock(), _createBlock(_component_Tooltip, {
                                key: 0,
                                tooltipContent: $data.contents.unpublishedTooltip
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_MButton, {
                                    theme: "bordered-danger",
                                    disabled: "",
                                    label: $data.contents.buttonRemoveLabel
                                  }, null, 8, ["label"])
                                ]),
                                _: 1
                              }, 8, ["tooltipContent"]))
                            : (_openBlock(), _createBlock(_component_MButton, {
                                key: 1,
                                theme: "bordered-danger",
                                label: $data.contents.buttonRemoveLabel,
                                onClick: $options.remove
                              }, null, 8, ["label", "onClick"])),
                          _createVNode(_component_MButton, {
                            theme: "bordered-neutral",
                            label: $data.contents.buttonCancelLabel,
                            onClick: $options.cancel
                          }, null, 8, ["label", "onClick"]),
                          _createVNode(_component_MButton, {
                            "data-cy": "save-the-route",
                            label: $data.contents.buttonUpdateLabel,
                            onClick: $options.update
                          }, null, 8, ["label", "onClick"])
                        ], 64))
                      : (_openBlock(), _createBlock(_component_MButton, {
                          key: 1,
                          theme: "solid-neutral",
                          label: $data.contents.buttonEditLabel,
                          onClick: $options.edit
                        }, null, 8, ["label", "onClick"]))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", {
                for: `update-or-remove-target-url-${$props.form.id}`
              }, [
                _createTextVNode(_toDisplayString($data.contents.targetUrl) + " ", 1),
                (!$props.isDisabled && $data.isEditing)
                  ? (_openBlock(), _createBlock(_component_Tooltip, {
                      key: 0,
                      tooltipContent: $data.contents.targetUrlTooltip
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_MIcon, { name: "NotificationQuestion24" })
                      ]),
                      _: 1
                    }, 8, ["tooltipContent"]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_5),
              _createVNode(_component_TextInput, {
                modelValue: $options.url,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.url) = $event)),
                class: "margin-bottom-400",
                reference: "inputTargetUrl",
                placeholder: $data.contents.targetUrlPlaceholder,
                isDisabled: !$data.isEditing,
                error: $data.urlValidationError
              }, null, 8, ["modelValue", "placeholder", "isDisabled", "error"])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass([
          'gap-800',
          {
            'has-error': $props.form.hasError('zoneIds'),
          },
        ])
            }, [
              _createElementVNode("label", null, [
                _createTextVNode(_toDisplayString($data.contents.gateways) + " ", 1),
                (!$props.isDisabled && $data.isEditing)
                  ? (_openBlock(), _createBlock(_component_Tooltip, {
                      key: 0,
                      tooltipContent: $data.contents.gatewaysTooltip
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_MIcon, { name: "NotificationQuestion24" })
                      ]),
                      _: 1
                    }, 8, ["tooltipContent"]))
                  : _createCommentVNode("", true)
              ]),
              ($data.isEditing)
                ? (_openBlock(), _createBlock(_component_MNotification, {
                    key: 0,
                    class: "update-or-remove-target-url__alert-message-gateway",
                    type: "information"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($data.contents.infoAskAnotherGateway), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_SelectZones, {
                form: $props.form,
                "is-disabled": !$data.isEditing
              }, null, 8, ["form", "is-disabled"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["update-or-remove-target-url__dynamics-routing", {
          'update-or-remove-target-url__dynamics-routing--disabled': $props.isDisabled,
        }])
            }, [
              _createVNode(_component_DynamicRouting, {
                routingPolicies: $data.currentlyUpdatingRoutingPolicies,
                routeEntryPoints: $options.downstreamEntryPoints,
                isDisabled: !$data.isEditing,
                isDisplayDynRoutingNotification: $data.isDisplayDynRoutingNotification,
                onUpdate: $options.updateRoutingPolicies,
                onDisplayNotification: $options.displayDynRoutingNotification
              }, null, 8, ["routingPolicies", "routeEntryPoints", "isDisabled", "isDisplayDynRoutingNotification", "onUpdate", "onDisplayNotification"])
            ], 2)
          ], 34)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}