<template>
  <div
    :class="[
      'slides-layout',
      'slides-layout--background-navbar-visible',
      {
        'slides-layout--modal-open': isOpenResponsiveMenu,
      },
    ]"
  >
    <div class="slides-layout__navbar">
      <div class="content">
        <slot name="navbar">
          <top-navbar @toggleNavigationModal="toggleNavigationModal" />
        </slot>
      </div>
    </div>
    <div class="slides-layout__content">
      <div v-slides="slidesConfiguration" class="slides-layout__slides">
        <slot name="slides" />
      </div>
      <nav class="slides-layout__anchors-navigation">
        <ul>
          <li v-for="(anchor, index) in anchors" :key="anchor">
            <a
              :class="{
                'slides-layout__anchors-navigation--active':
                  index === currentSlideIndex,
              }"
              @click.prevent="navigateToAnchor(anchor)"
              ><span></span
            ></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import TopNavbar from "@/commons/components/TopNavbar.vue";

import { isMobileSize } from "@/commons/libs/is-mobile-size";

export default {
  name: "SlidesLayout",
  components: {
    TopNavbar,
  },
  data() {
    return {
      slides: null,
      isOpenResponsiveMenu: false,
      isBegginingOfScreen: true,
      slidesConfiguration: {
        animation: 300,
        delay: 0,
        pips: false,
      },
      currentSlideIndex: 0,
    };
  },
  computed: {
    anchors() {
      return (this.slides && this.slides.anchors) || [];
    },
  },
  mounted() {
    this.slides = (
      document.querySelector(".slides-layout__slides") as any
    ).$slides;
    this.slides.on(
      "indexChange",
      (slideIndex) => (this.currentSlideIndex = slideIndex),
    );
    this.updateIsBegginingOfScreen();
    this.resize();
    window.addEventListener("wheel", this.updateIsBegginingOfScreen);
    window.addEventListener("scroll", this.updateIsBegginingOfScreen);
    window.addEventListener("resize", this.resize);
  },
  unmounted() {
    this.slides.destroy();
    window.removeEventListener("wheel", this.updateIsBegginingOfScreen);
    window.removeEventListener("scroll", this.updateIsBegginingOfScreen);
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    toggleNavigationModal() {
      this.isOpenResponsiveMenu = !this.isOpenResponsiveMenu;
    },
    navigateToAnchor(hash) {
      this.slides.scrollToAnchor(hash);
    },
    updateIsBegginingOfScreen() {
      this.isBegginingOfScreen = window.scrollY === 0;
    },
    resize() {
      if (isMobileSize(window.innerWidth)) {
        this.slides.destroy();
      } else {
        this.slides.init();
      }
    },
  },
};
</script>

<style lang="scss">
@import "./slides-navigation";
@import "~@/commons/assets/styles/vars";

.slides-layout {
  width: 100%;
  overflow-y: hidden;
}

.slides-layout__navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--z-index-middleground);

  min-height: 5rem;

  background-color: transparent;

  box-shadow: none;
  transition: background-color 0.5s ease;
}

.slides-layout__content {
  width: 100%;
}

.slides-layout__content .content {
  width: var(--layout-content-width);
  margin: auto;
}

.slides-layout--background-navbar-visible .slides-layout__navbar {
  background-color: var(--color-white);

  box-shadow: var(--m-shadow-medium);

  transition: background-color 0.5s ease;
}
/* stylelint-disable scss/no-global-function-names */
@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  .layout__navbar .content,
  .layout__content .content {
    width: var(--layout-content-width-m);
  }
}

@media only screen and (max-width: map-get($breakpoints, mobile)) {
  .slides-layout__slides {
    width: 100%;
  }

  .slides-layout .modal {
    position: absolute;
  }
}
</style>
