<template>
  <div class="use-case-list-features">
    <div class="use-case-list-features__header">
      {{ contents.listFeaturesHeader }}
    </div>
    <ul>
      <li
        v-for="(feature, index) in features"
        :key="`use-case-list-features-feature-${feature.name}`"
        class="use-case-list-features__feature"
      >
        <a
          class="use-case-list-features__feature-content"
          @click.prevent="
            $emit('navigateToAnchor', `#use-case-feature-${feature.name}`)
          "
        >
          <span class="use-case-list-features__number">{{
            pad(index + 1)
          }}</span>
          <span class="use-case-list-features__title">{{ feature.title }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
const contents = {
  listFeaturesHeader: "Key features",
};

export default {
  name: "UseCaseListFeatures",
  props: {
    features: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["navigateToAnchor"],
  data() {
    return { contents };
  },
  methods: {
    pad(num) {
      let str = num.toString();
      while (str.length < 2) str = "0" + str;
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";

.use-case-list-features__header {
  margin: 0 0 3rem;
  font-size: 0.86rem;
  font-weight: 700;
  color: var(--color-white);
  text-transform: uppercase;
}

.use-case-list-features__feature {
  margin: 0 0 2.5rem;
  list-style-type: none;
  border-bottom: 1px solid var(--color-white);

  &:last-child {
    margin: 0;
  }
}

.use-case-list-features__feature-content {
  display: flex;
  align-items: center;

  height: 4rem;
  overflow: hidden;

  color: var(--color-white);

  text-decoration: none;
  cursor: pointer;
}

.use-case-list-features__number {
  width: 5rem;

  margin: 0 2rem 0 0;

  font-size: 3.5rem;
  font-weight: var(--weight-light);
  line-height: 1;
  text-align: center;
  letter-spacing: 0.3rem;
  opacity: 0.45;
}

.use-case-list-features__title {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 2rem;
}
/* stylelint-disable scss/no-global-function-names */
@media only screen and (max-width: map-get($breakpoints, tablet-landscape)) {
  .use-case-list-features__feature {
    margin: 0 0 1.8rem;
  }

  .use-case-list-features__number {
    width: 4rem;
    font-size: 3rem;
  }
}
</style>
