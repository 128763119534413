import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4fdad870"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-anchor"]
const _hoisted_2 = { class: "slide__scroll-down" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "slide",
    "data-anchor": $props.anchor,
    style: _normalizeStyle($options.imageStyles)
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require('@/commons/assets/images/scroll-mouse.svg')
      }, null, 8, _hoisted_3),
      _createElementVNode("span", null, _toDisplayString($data.contents.scrollDownButton), 1)
    ])
  ], 12, _hoisted_1))
}