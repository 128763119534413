import store from "@/commons/store";

import { ModalClosedError } from "@/commons/components/Modal/modal.type";

import {
  CancelledHttpError,
  HttpResponseError,
} from "@/commons/request-handlers/HttpResponseError";

function handleHttpRequestError(error: HttpResponseError): void {
  // Canceling HTTP requests is a normal workflow so we just don't do anything when it happens
  if (error instanceof CancelledHttpError) {
    return;
  }

  store.commit("postErrorNotification", {
    message: error.message,
    link: error.link
      ? {
          href: error.link?.href,
          label: error.link?.label,
          openInNewTab: true,
        }
      : undefined,
  });
  console.error(error);
}

function handleError(error: Error): void {
  // List of errors to ignore. They are part of the normal workflow and are not considered as errors to log
  if (error instanceof ModalClosedError) {
    return;
  }

  if (error instanceof HttpResponseError) {
    handleHttpRequestError(error);
  } else {
    console.error(error);
  }
}

export function addErrorListeners(app): void {
  // Event listener for 'unhandledrejection' is for unhandled rejections AND for uncaught errors happening in Promises
  window.addEventListener(
    "unhandledrejection",
    (event: PromiseRejectionEvent) => {
      // unhandledrejection is called in 2 cases:
      // - When a promise got rejected (Promise.reject(value)) with no rejection handling. In that case event contains the value
      // - When an error is thrown in a promise. In that case event contains the thrown Error
      // We only want to globally handle Errors, we keep the default behaviour for rejected value as we don't want any global behaviour for it
      if (event.reason instanceof Error) {
        event.preventDefault(); // To prevent from logging the default console.error("Uncaught (in promise) Error...")
        handleError(event.reason);
      }
    },
  );

  // Event listener for 'error' is for uncaught errors happening outside the Vue engine
  window.addEventListener("error", (event: ErrorEvent) => {
    handleError(event.error);
  });

  // App.config.errorHandler is for uncaught errors happening inside the Vue engine
  app.config.errorHandler = (error: Error) => handleError(error);
}
