import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4b34656c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lm-fs-12 flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'is-dark': $props.dark }, "shortcuts-container"]),
    style: _normalizeStyle([{ height: `${$props.height}px` }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.customShortcuts, (shortcut) => {
      return (_openBlock(), _createBlock(_component_CustomButton, {
        key: shortcut.key,
        dark: $props.dark,
        color: $props.color,
        selected: $data.selectedShortcut === shortcut.key,
        "with-border": "",
        class: "shortcut-button",
        onClick: ($event: any) => ($options.select(shortcut))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(shortcut.label), 1)
        ]),
        _: 2
      }, 1032, ["dark", "color", "selected", "onClick"]))
    }), 128))
  ], 6))
}