<script setup lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import MFlag from "@mozaic-ds/vue-3/src/components/flag/MFlag.vue";
import { computed, PropType, ref } from "vue";
import { useStore } from "vuex";

import ButtonCopyCredentials from "@/commons/components/Buttons/ButtonCopyCredentials.vue";
import IconButton from "@/commons/components/IconButton.vue";

import { toLocaleDate } from "@/commons/utils/date-utils";

import { Token } from "@/commons/domain/models/token";

import {
  ETokenStatus,
  ETokenSynchronizationStatus,
} from "@/commons/store/types";

import contents from "@/dashboard/contents/contract-api-key-display";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  token: {
    type: Object as PropType<Token>,
    required: true,
  },
  showActionsButton: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["openActionsMenu"]);

const store = useStore();

const formattedExpirationDate = computed((): string => {
  return toLocaleDate(props.token.expireAt);
});

const isDisabled = computed(() => props.token.status === ETokenStatus.DISABLED);

const isExpired = computed(() => props.token.status === ETokenStatus.EXPIRED);

const expirationDateStatus = computed((): string => {
  if (isDisabled.value) {
    return "neutral";
  }
  if (isExpired.value) {
    return "danger";
  }
  if (props.token.isAboutToExpire) {
    return "warning";
  }
  return "success";
});

const currentApplicationTokensSynchronizationStatuses = computed(() => {
  return store.getters[
    "currentApplicationTokensSynchronizationStatuses"
  ] as Map<string, ETokenSynchronizationStatus>;
});

function tokenHasSynchronizationStatus(tokenId: string): boolean {
  return (
    currentApplicationTokensSynchronizationStatuses.value != undefined &&
    currentApplicationTokensSynchronizationStatuses.value[tokenId] != undefined
  );
}

function getTokenSynchronizationStatus(
  tokenId: string,
): ETokenSynchronizationStatus {
  return currentApplicationTokensSynchronizationStatuses.value[tokenId];
}

function getTokenSynchronizationStatusTheme(tokenId: string): string {
  const tokenStatus = getTokenSynchronizationStatus(tokenId);

  return tokenStatus === ETokenSynchronizationStatus.SYNCHRONIZED
    ? "primary"
    : getPendingTokenSynchronizationStatus();
}

const getPendingTokenSynchronizationStatus = () => {
  return secretSynchronizationIsEnabled.value ? "bordered" : "danger";
};

const secretSynchronizationIsEnabled = computed(
  () => store.getters["config/secretSynchronizationIsEnabled"],
);

const currentApplicationSecretSynchronizationEnabled = computed(
  () => store.getters["currentApplicationSecretSynchronizationEnabled"],
);

const secretManager = computed(
  () => store.getters["config/secretSynchronizationManager"],
);

const expirationLabel = computed((): string => {
  if (isExpired.value) {
    return contents.expiredSinceLabel(formattedExpirationDate.value);
  }

  return contents.expiresAtLabel(formattedExpirationDate.value);
});

const getTokenSyncLabel = (status: ETokenSynchronizationStatus) => {
  if (status === ETokenSynchronizationStatus.SYNCHRONIZED) {
    return contents.synchronizedTokenLabel(secretManager.value);
  } else if (status === ETokenSynchronizationStatus.PENDING) {
    return contents.pendingSyncTokenLabel(secretManager.value);
  } else {
    return undefined;
  }
};

const hideContent = ref(true);

const computedSecretContent = computed(() => {
  return hideContent.value
    ? "••••••••••••••••••••••••••••••••••••"
    : props.token.key;
});
</script>

<template>
  <div class="contract-api-key-display" :data-cy="token.id">
    <span class="contract-api-key-display__label">
      <strong>{{ label }}</strong>
    </span>
    <div class="contract-api-key-display__details">
      <div class="contract-api-key-display__badges">
        <MBadge data-cy="token-expiration-date" :type="expirationDateStatus">
          {{ expirationLabel }}
        </MBadge>
        <MBadge v-if="isDisabled" data-cy="token-status" type="neutral">
          {{ contents.disabledLabel }}
        </MBadge>
      </div>
      <div class="contract-api-key-display__flags">
        <MFlag
          v-if="
            !isDisabled &&
            currentApplicationSecretSynchronizationEnabled &&
            tokenHasSynchronizationStatus(token.id)
          "
          :label="getTokenSyncLabel(getTokenSynchronizationStatus(token.id))"
          :theme="getTokenSynchronizationStatusTheme(token.id)"
        />
      </div>
    </div>

    <div class="contract-api-key-display__credential">
      <IconButton
        v-if="showActionsButton"
        data-cy="token-actions-menu"
        mozaicIconName="DisplaySetting24"
        @click="emit('openActionsMenu')"
      />
      <ButtonCopyCredentials :content="token.key" />
      <div
        class="contract-api-key-display__secret-container"
        @mouseover="hideContent = false"
        @mouseout="hideContent = true"
      >
        <IconButton mozaicIconName="DisplayView24" />
        <div class="contract-api-key-display__secret">
          {{ computedSecretContent }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.contract-api-key-display {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contract-api-key-display__details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contract-api-key-display__badges {
  display: flex;
  gap: 1rem;
}

.contract-api-key-display__credential {
  display: flex;
  gap: 0.375rem;
  align-items: center;
}

.contract-api-key-display__secret-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  height: 2.5rem;

  &:hover {
    cursor: pointer;
  }
}

.contract-api-key-display__secret {
  width: 13rem;
  font-size: 14px;
}
</style>
