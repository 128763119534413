<script setup lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import { computed, PropType } from "vue";

import ViewHelpLink from "@/commons/components/UserDocumentationLinks/ViewHelpLink.vue";

import contents from "@/dashboard/contents/warning-notification-recommended-actions";

const props = defineProps({
  actions: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
});

const dynamicTitle = computed(() => {
  return props.actions?.length === 1
    ? contents.titleSingleIssue
    : contents.titleMultipleIssues;
});
</script>

<template>
  <MNotification
    v-if="actions?.length > 0"
    type="warning"
    :title="dynamicTitle"
  >
    <p>{{ contents.subtitle }}</p>
    <ul>
      <li v-for="action in actions" :key="action">{{ action }}</li>
    </ul>
    <template #footer>
      <ViewHelpLink :documentationAnchor="contents.docLinkAnchor" />
    </template>
  </MNotification>
</template>
